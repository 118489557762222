<template>
    <v-container>
        <div class="d-flex">
            <v-btn
                icon
                color="accent darken-3"
                class="align-self-center"
                link
                :to="prevRoutePath"
            >
                <v-icon left> fa-solid fa-chevron-left </v-icon>
            </v-btn>
            <h2 class="primary--text main-heading">
                agROBOfood Business Membership Terms
            </h2>
        </div>
        <v-divider class="mx-4" inset vertical> </v-divider>
        <v-spacer></v-spacer>

        <base-card>
            <template #content>
                <v-card-text>
                    <h2 class="text-h5 font-weight-bold">
                        Membership and Application
                    </h2>
                    <ul>
                        <li>
                            A business membership is on a voluntary basis and
                            applicable for Small, Medium and Large- Scale
                            Enterprises (SMEs and LSEs) doing business by
                            delivering products or services within the Agro-Food
                            and Robotics domain.
                        </li>
                        <li>
                            Joining the agROBOfood network is free of charge at
                            least until June 1st, 2022. A membership fee might
                            be applicable from that moment on depending on the
                            long-term sustainability strategy to be agreed by
                            the project partners.
                        </li>
                        <li>
                            Applications to become a business member can be send
                            through the agROBOfood website
                            <a href="https://agrobofood.eu/">
                                (www.agrobofood.eu)
                            </a>
                            .
                        </li>
                        <li>
                            Applicants are required to sign the application form
                            and state that they will respect the general GDPR
                            policies.
                        </li>
                        <li>
                            Applicants will receive a notification from the
                            administrator via e-mail whether they are eligible
                            to become a business member and to which agROBOfood
                            Regional Cluster they belong. Upon confirmation by
                            the applicant via an e-mail to the administrator,
                            the new business membership will start.
                        </li>
                        <li>
                            New business members will be appointed a contact
                            person, being a local DIH-node (orchestrator) or in
                            cases no local DIH-node is available the Regional
                            Cluster Leader.
                        </li>
                        <li>
                            Business members have the right to step out of the
                            network as a member and to be deleted from the
                            e-mail lists and newsletters. To withdraw,
                            associated members must send a request to the
                            administrator (coordinator) for that.
                        </li>
                        <li>
                            In case business members do not respond for a period
                            of more than one year to general communications or
                            are not active in the network, after prior notice,
                            the agROBOfood network may delete the associated
                            member from the website and from the e-mail lists
                            and discard access to the agROBOfood portal.
                        </li>
                    </ul>

                    <h2 class="text-h5 font-weight-bold">
                        Access to the agROBOfood network and information
                    </h2>
                    <ul>
                        <li>
                            Business members will be entitled to use the
                            agROBOfood logo for communication purposes. Each
                            Regional Cluster will use a specific colored version
                            of that logo being available from the website.
                        </li>
                        <li>
                            Business members will automatically receive the
                            agROBOfood newsletter.
                        </li>
                        <li>
                            Business members will have access to their specific
                            Regional Cluster folder on the agROBOfood portal.
                        </li>
                        <li>
                            Business members will have access to contacts within
                            the agROBOfood network, made available as e-mail
                            lists.
                        </li>
                        <li>
                            Business members will have the right to join general
                            agROBOfood meetings and joined activities for free
                            (no entrance fee).
                        </li>
                        <li>
                            Business members, not being an agROBOfood consortium
                            project partner, may make use of the open calls.
                        </li>
                        <li>
                            Business members have access to public available
                            catalogues and tools on the agROBOfood website.
                        </li>
                        <li>
                            Registered business members will not receive any
                            financial compensation from the agROBOfood project.
                            Costs incurred by business members for agROBOfood
                            network activities must be recovered from the
                            members’ own funds (from own budget, clients,
                            subsidies etc).
                        </li>
                        <li>
                            Business members may promote a “Show Case” (Best
                            Practice or Success Story) linked to a specific DIH
                            on the agROBOfood website.
                        </li>
                        <li>
                            Business members may collaborate in disseminating
                            agROBOfood content throughout their own networks
                            (clients newsletters etc.).
                        </li>
                        <li>
                            Business Members must show that they are active in
                            the agro-food-robotics domain. For
                        </li>
                    </ul>
                </v-card-text>
            </template>
        </base-card>
    </v-container>
</template>

<script>
import BaseCard from "@/components/base/BaseCard.vue";
export default {
    name: "SmeTerms",

    components: { BaseCard },

    data() {
        return {
            prevRoute: null,
        };
    },
    computed: {
        prevRoutePath() {
            return this.prevRoute ? this.prevRoute.path : "/";
        },
    },

    beforeRouteEnter(_, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },
};
</script>

<style scoped>
h2 {
    padding: 15px 0;
}

li {
    font-size: 0.9rem;
    padding: 4px 0;
}
</style>
